$gallery-padding:     5px;

.gallery-col-1 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
	
	padding: $gallery-padding;
}

.gallery-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));

	padding: $gallery-padding;
}

.gallery-col-3 {
    width: -webkit-calc(33.29% - (#{$spacing-unit} / 2));
    width:         calc(33.29% - (#{$spacing-unit} / 2));

	padding: $gallery-padding;
}
